//import axios from 'axios';
import http from "../../axios/axios_conf";
import api from "../../routers/routers";

const userData = JSON.parse(localStorage.getItem("user"));

class Users {
  searchuseremail(search_data) {
    return http.get(
      "https://devapi.homeapp.cl/users/search_user?search=" + search_data
    );
    //return http.get(api.users.search_user.endpoint+'?search'+search_data);
  }
  getCurrentUserProfile() {
    return http.get(api.users.profile.endpoint);
  }

  updateUserProfile(data) {
    return http.put(api.users.update_profile.endpoint, data);
  }

  editUsers(uuid, data) {
    return http.put(api.users.edit_users.endpoint + uuid, data);
  }

  updatePassword(data) {
    return http.put(api.users.update_password.endpoint, data);
  }

  resetPassword(data) {
    return http.put(api.users.restore_password.endpoint, data);
  }

  my_buildings(data) {
    return http.get(api.users.my_buildings.endpoint, data);
  }

  select_building(building_data) {
    return http.put(api.users.select_building.endpoint, null, building_data);
  }

  create_user(user_data) {
    return http.post(api.users.create.endpoint, user_data);
  }

  update_administrador(uuid, data) {
    return http.put(api.users.update_user.endpoint + uuid, data);
  }

  upload_profile_picture(file, params) {
    return http.post(api.users.profile_picture.endpoint + params, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export default new Users();
